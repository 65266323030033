import React, { useState, useEffect } from "react";
import { logout } from "../../helpers/authHelper";
import { LockClosedIcon, UserIcon, CogIcon, Bars3Icon } from "@heroicons/react/24/solid";
import { getAuth, updateProfile } from "firebase/auth";

const UserProfileModal = ({ user, isOpen, onClose }) => {
    const [isEditable, setIsEditable] = useState(false);
    const [displayName, setDisplayName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [uid, setUid] = useState('');
    const [verifiedStatus, setVerifiedStatus] = useState(false);

    useEffect(() => {
        if (user) {
            setDisplayName(user.displayName || '');
            setPhoneNumber(user.phoneNumber || '');
            setEmail(user.email || '');
            setUid(user.uid || '');
            setVerifiedStatus(user.verifiedStatus || false);
            console.log("User received in UserProfileModal:", user);
        }
    }, [user]);

    const handleEditClick = async () => {
        if (isEditable) {
            // Save the profile changes to Firebase when the form is editable
            try {
                const auth = getAuth();
                await updateProfile(auth.currentUser, {
                    displayName: displayName,
                    email: email // Note: To update email, you should use updateEmail instead of updateProfile
                });
                onClose(); // Optionally close the modal on save
            } catch (error) {
                console.error("Failed to update profile:", error);
            }
        }
        setIsEditable(!isEditable);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'displayName':
                setDisplayName(value);
                break;
            case 'phoneNumber':
                setPhoneNumber(value);
                break;
            case 'email':
                setEmail(value);
                break;
            default:
                break;
        }
    };

    if (!isOpen || !user) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-80 overflow-y-auto h-full w-full flex justify-center items-center">
            <div className="relative p-4 bg-white bg-opacity-80 rounded-lg shadow-lg max-w-3xl w-full mx-4 md:mx-auto">
                <button onClick={onClose} className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 focus:outline-none">
                    <span>&times;</span>
                </button>
                <h1 className="text-xl text-center font-bold mb-4 text-black">User Profile</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <InputField label="Name" name="displayName" value={displayName} onChange={handleInputChange} disabled={!isEditable} />
                    <InputField label="Phone" name="phoneNumber" value={phoneNumber} onChange={handleInputChange} disabled={!isEditable} />
                    <InputField label="Email Address" name="email" value={email} onChange={handleInputChange} disabled={!isEditable} />
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Verification Status</label>
                        <div className={`px-2 py-1 rounded ${verifiedStatus ? 'bg-green-500' : 'bg-red-500'} text-white`}>
                            {verifiedStatus ? 'Verified' : 'Not Verified'}
                        </div>
                    </div>      
                </div>
                <div className="grid grid-cols-1 mt-3">
                    <InputField label="User ID" name="uid" value={uid} onChange={handleInputChange} disabled={true} />
                </div>
                <div className="text-center">                    
                    <button
                        className="generalButton flex items-center justify-center focus:outline-none mt-4 bg-gray-900"
                        onClick={handleEditClick}
                    >
                        <CogIcon className="h-5 w-5 text-white-500 mr-2" aria-hidden="true" />
                        {isEditable ? 'Save Changes' : 'Edit Profile'}
                    </button>
                </div>
            </div>
        </div>
    );
};

const InputField = ({ label, name, value, onChange, disabled }) => (
  <div>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <input
          type="text"
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          className="w-full px-2 py-1 border rounded text-black"
      />
  </div>
);

const TopBar = ({ toggleSidebar }) => {
    const [user, setUser] = useState(null);
    const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const auth = getAuth();
                const currentUser = auth.currentUser;
                if (currentUser) {
                    const userData = {
                        displayName: currentUser.displayName,
                        email: currentUser.email,
                        phoneNumber: currentUser.phoneNumber,
                        uid: currentUser.uid,
                        verifiedStatus: currentUser.emailVerified // Assuming email verification status as verification status
                    };
                    setUser(userData);
                    console.log("User data fetched successfully:", userData);
                }
            } catch (e) {
                console.log("Error fetching user data:", e);
            }
        };

        fetchUserData();
    }, []);

    const handleLogout = () => {
        logout();
    };

    const toggleProfileModal = () => {
        setIsProfileModalOpen(!isProfileModalOpen);
    };

    return (
        <div className="bg-gray-900 p-2 px-3 flex items-center text-white w-full fixed top-0 left-0 z-10 grid grid-cols-3">
            {/* First division: Menu button shown only on mobile screens */}
            <div className="flex items-center justify-start">
                <button onClick={toggleSidebar} className="p-2 rounded-md text-white md:hidden focus:outline-none">
                    <Bars3Icon className="h-6 w-6" />
                </button>
            </div>

            {/* Second division: Centered Logo */}
            <div className="flex justify-center">
                <img src={require("./../../assets/images/nest logo light.png")} alt="Logo" className="h-12" />
            </div>

            {/* Third division: Profile and Logout buttons */}
            <div className="flex justify-end items-center">
                <button onClick={toggleProfileModal} className="generalButton flex items-center focus:outline-none">
                    <UserIcon className="h-5 w-5 text-white-500" aria-hidden="true" />
                    <span className="hidden lg:block ml-2">Profile</span> {/* Text visible only on large screens */}
                </button>
                <UserProfileModal user={user} isOpen={isProfileModalOpen} onClose={toggleProfileModal} />
                
                <button onClick={handleLogout} className="generalButton flex items-center ml-3 focus:outline-none">
                    <LockClosedIcon className="h-5 w-5 text-white-500" aria-hidden="true" />
                    <span className="hidden lg:block ml-2">Logout</span> {/* Text visible only on large screens */}
                </button>
            </div>
        </div>
    );
};

export default TopBar;
