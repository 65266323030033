import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for prop validation

const LoadingModal = ({ loadingModalVisible }) => {
  return (
    loadingModalVisible && (
      <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-40">
        <div className="animate-bounce rounded-full h-12 w-12 primaryBG"></div>
      </div>
    )
  );
};

LoadingModal.propTypes = {
  loadingModalVisible: PropTypes.bool.isRequired,
};

export default LoadingModal;
