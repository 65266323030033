import axios from "axios";
import { getApiConfig } from "./authHelper";
import { logout } from "./authHelper";

const BASE_URL = "http://localhost:3000";
//const BASE_URL = "https://nest-express.vercel.app";

export const axiosApi = axios.create({
  baseURL: BASE_URL,
});

axiosApi.interceptors.response.use(
  (response) => {
    if (response && response.status === 401) {
      //logout();
    }
    return response;
  },
  (error) => {

    if (error.response && error.response.status === 401) {
      //logout();
    }
    return error;
  }
);

export const refreshIDToken = async () => {	
	
	let refreshToken = localStorage.getItem("refreshToken");

	return axiosApi.post("https://securetoken.googleapis.com/v1/token?key=AIzaSyDS_5DT5yWOOuWZWagW7vltAK9wtL0werk", 
		{
			"grant_type": "refresh_token",
			"refreshToken": refreshToken
		},
		{"Content-Type": `application/json`})
			.then((response) => { 
				//console.log(response)
				//console.log(response.data)
				return response.data.id_token})
			.catch((error) => { 
				console.log(error)
			});
};



export const get = async (url, config = {}) => {
  return axiosApi.get(url, { ...config }).then((response) => response.data);
};

export const authenticatedGet = async (url, config = {}) => {
  //Add auth token to header
  let token = await refreshIDToken();
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  config = { ...config, headers };
  return axiosApi.get(url, { ...config }).then((response) => response.data);

};

export const authenticatedGetExcelDownload = async (url, fileName) => {
  try {
    //Add auth token to header
    let token = await refreshIDToken();
    
    let config = { 
      headers: {Authorization: `Bearer ${token}`},
      responseType: "blob", // Set the response type to 'blob' for binary data
    };
    
    
    //Unique to this function, need to set response type to blob
    return axios
      .get(BASE_URL + url, { ...config })
      .then((response) => {
        // Create a blob URL for the response data
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);

        // Create an invisible anchor element to trigger the download
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = fileName;

        // Trigger the click event to start the download
        document.body.appendChild(a);
        a.click();

        // Clean up by revoking the blob URL
        window.URL.revokeObjectURL(url);

        //Return response to catch status code and hide loader
        return response;
      })
      .catch((error) => {
        console.error("Download failed:", error);
      });
  } catch (error) {
    // Handling any errors that occur during file upload or processing
    console.error(error);
  }
};

export const postRequest = async (url, data = {}, config = {}) => {
	//Add auth token to header
	let token = await refreshIDToken();
	let headers = {
		"Content-Type": `application/json`,
		Authorization: `Bearer ${token}`,
	};

	config = { ...config, headers };
	return axiosApi
		.post(url, { ...data }, { ...config })
		.then((response) => { return response.data})
    .catch((error) => {
      // Handle errors
      console.error("Post request failed:", error);
    });
};

export const postRequestUnauthenticated = async (url, data = {}, config = {}) => {
	//Add auth token to header
	let headers = {
		"Content-Type": `application/json`
	};

	config = { ...config, headers };
	return axiosApi
		.post(url, { ...data }, { ...config })
		.then((response) => { return response.data})
    .catch((error) => {
      // Handle errors
      console.error("Post request failed:", error);
    });
};

export const postRequestFileUpload = async (url, formData) => {
	//Add auth token to header
	let token = await refreshIDToken();
	let headers = {
    "Content-Type": "multipart/form-data", //Content type is different for file upload
		Authorization: `Bearer ${token}`,
	};

	let config = { ...config, headers };
	return axiosApi
		.post(url, formData, { ...config })
		.then((response) => { return response })
    .catch((error) => {
      // Handle errors
      console.error("File upload failed:", error);
    });

};

export const postRequestReturnAll = async (url, data = {}, config = {}) => {
	//Add auth token to header
	let token = await refreshIDToken();
	let headers = {
		"Content-Type": `application/json`,
		Authorization: `Bearer ${token}`,
	};

	config = { ...config, headers };
	return axiosApi
		.post(url, { ...data }, { ...config })
		.then((response) => response)
    .catch((error) => {
      // Handle errors
      return error;
    });
};


export const postRequestWithoutToken = async (url, data = {}, config = {}) => {
  //Add auth token to header
  // let token = await refreshIDToken();
  let headers = {
    "Content-Type": `application/json`,
    // "Authorization": `Bearer ${token}`,
  };

  config = { ...config };
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
};

export const put = async (url, data, config = {}) => {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
};

export const del = async (url, config = {}) => {
  return axiosApi.delete(url, { ...config }).then((response) => response.data);
};

export const postCreateJob = async (url, data, config = {}) => {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
};

export const getSearchClient = async (url, data, config) => {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => {
      return response.data;
    })
    .catch((err) => console.error(err));
};
