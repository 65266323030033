import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import "./index.css";
import "./assets/stylesheets/global.css";
import "./firebase";

// Import public components
import AuthLayout from "./views/AuthLayout";
import Landing from "./pages/public/landing";
import SignIn from "./pages/public/signin";
import SignUp from "./pages/public/signUp";
import ForgotPassword from "./pages/public/forgot-password";
import QuickEstimate from "./pages/public/quickEstimate";


// Import authenticated components
import Home from "./pages/authenticated/home";
import Overview from "./pages/authenticated/overview";

import Estimate from "./pages/authenticated/estimate";
import Quotes from "./pages/authenticated/quotes";
import Quote from "./pages/authenticated/quote";
import MyNest from "./pages/authenticated/myNest";

import Support from "./pages/authenticated/support";

const ProtectedRoute = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
            setIsLoading(false);
        });

        return () => unsubscribe();
    }, []);

    if (isLoading) {
        return(
			<div className="flex justify-center items-center h-screen"> {/* Full screen centering */}
				<div className="h-4 w-4 primaryBG rounded-full mx-1 animate-pulse"></div>
				<div className="h-4 w-4 primaryBG rounded-full mx-1 animate-pulse animation-delay-200"></div>
				<div className="h-4 w-4 primaryBG rounded-full mx-1 animate-pulse animation-delay-400"></div>
			</div>
		) 
    }

    if (!user) {
        // Redirect to the sign-in page, but save the current location they were trying to go to
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setIsAuthenticated(!!user);
            setIsLoading(false);
        });

        return () => unsubscribe();
    }, []);

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="loader"></div> {/* Update this part with your loading indicator */}
            </div>
        );
    }

    return (
        <>
            <Routes>
                <Route
                    path="/"
                    element={
                        isAuthenticated ? 
							<ProtectedRoute>
								<AuthLayout />
							</ProtectedRoute> : <Landing />
                    }
                >
					{/* Protected routes can be wrapped with ProtectedRoute for additional auth checks */}
					
					<Route path="/" element={<Home headerTitle={"Home"} />} />
                    
					<Route path="/overview" element={<Overview headerTitle={"Overview"} />} />

					<Route path="/estimate" element={<Estimate headerTitle={"Estimate"} />} />
					<Route path="/quotes" element={<Quotes headerTitle={"Quotes"} />} />
					
					<Route path="/quote" element={<Quote headerTitle={"Quote"} />} />

					<Route path="/myNest" element={<MyNest headerTitle={"My nest"} />} />

					<Route path="/support" element={<Support headerTitle={"Support"} />} />
                    

                </Route>
                <Route path="/quickEstimate" element={<QuickEstimate />} />
				
                <Route path="/login" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
				
            </Routes>
        </>
    );
}


export default App;