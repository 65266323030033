import React from 'react';
import PropTypes from 'prop-types';
import { XCircleIcon } from '@heroicons/react/24/solid'; // Import the X (times) icon

const ErrorModal = ({ modalVisible, modalActionFunction }) => {
  if (!modalVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 px-4">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
        <div className="flex flex-col items-center">
          <XCircleIcon className="h-12 w-12 text-red-800" /> {/* Use dark red color for the icon */}
          <p className="text-center text-gray-700 mt-2">
            An error has occurred, please try again.
          </p>
          <button
            className="mt-2 bg-red-800 text-white rounded py-1 px-4 hover:bg-red-900 focus:outline-none" // Use dark red color for the button
            onClick={modalActionFunction}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

ErrorModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  modalActionFunction: PropTypes.func.isRequired, // Correct the prop type validation
};

export default ErrorModal;
