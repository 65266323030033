import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for prop validation

const LoadingModal = ({ loadingModalVisible }) => {
  return (
    loadingModalVisible && (
      <div className="fixed inset-0 bg-black bg-opacity-80 flex flex-col justify-center items-center z-50 space-y-4 px-5">
        <div className="animate-bounce rounded-full h-12 w-12 primaryBG"></div>
        <div className="text-white text-center">Please wait while we prepare your estimate, this will take up to 30 seconds</div>
    </div>
    )
  );
};

LoadingModal.propTypes = {
  loadingModalVisible: PropTypes.bool.isRequired,
};

export default LoadingModal;
