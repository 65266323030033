import React, { useContext, useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import Logo from "./../../../assets/images/nest logo.png";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  let navigate = useNavigate();

  const handleSubmit = async () => {
    setIsLoading(true);
    if (!email) {
      setIsLoading(false);
      setError("Enter email address");
      return;
    }
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      setError("Password reset email sent successfully");
      setIsLoading(false);
      navigate("/signin");
    } catch (error) {
      let errorMessage = "";
      switch (error.code) {
        case "auth/invalid-email":
          errorMessage = "Enter a valid email address";
          break;
        case "auth/user-not-found":
          errorMessage = "User not found with entered email";
          break;
        default:
          errorMessage = error.message || "Error in sending password reset link";
          break;
      }
      setError(errorMessage);
      setIsLoading(false);
    }
  };

  return (
    <div className="fullSizeContainer">
      <div className="h-100 d-flex align-items-center justify-content-center">
        {isLoading ? (
          <span>Loading</span>
        ) : (
          <div
            className="card text-center p-3 align-items-center"
            style={{ maxWidth: "20%", minWidth: "350px" }}
          >
            <img className="mb-4" src={Logo} alt="" width="200" />
            <form onSubmit={(e) => { e.preventDefault(); handleSubmit() }} autoComplete="off">
              <label>
                Enter your Email below to reset your password. You will receive
                a link to reset your password to the entered email address
                registered to your inspectAPP account. Please make sure you
                check your junk/spam folder if it does not come through shortly.
              </label>
              <input
                className="form-control"
                id="email"
                type="email"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <br />
              {error && <div className="error-message">{error}</div>}
              <button className="btn btn-dark" type="submit">
                Request Password Reset
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
