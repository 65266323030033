import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { TrophyIcon, CalculatorIcon } from '@heroicons/react/24/solid';
import { postRequest, postRequestReturnAll } from "../../../helpers/apiHelper";
import { convertUnixTimestampShort } from "../../../helpers/unixTimeConverter";
import { toDollarFormat, calculateGrossReturnToInvestor, calculateAnnualCapitalReturnToInvestor, calculateAnnualCashYieldToInvestor } from "../../../helpers/calculatorHelper";
import LoadingModal from "../../../components/LoadingModal";
import WeGotTheHouseModal from "../../../components/WeGotTheHouseModal";
import ErrorModal from "../../../components/ErrorModal";

const wizards = [
    {
        complete: true,
        number: 1,
        title: "Negotiate with seller",
        text: "Based on your provided property purchase price, negotiate with the seller. If you need to bid higher than the estimated price, please provide that number in the final property price input field to see how nest agreement costs are impacted."
    },
    {
        complete: false,
        number: 2,
        title: "Seller accepts your offer",
        text: "To proceed, you need to ensure you are the successful bidder on the property. Please be sure to update the property purchase price on the left so that you understand the impact of any change in property sale price compared to your original estimate."
    },
    {
        complete: false,
        number: 3,
        title: "Confirm sale with us",
        text: "As soon as your offer is accepted, let us know and we can celebrate! We will then guide you through the rest of the process."
    }
];

const Step = ({ wizard }) => {
    return (
        <div className="flex flex-col">
            <div className="flex items-center mb-1">
                <div style={{ width: '20px', height: '20px' }}
                    className={`flex items-center justify-center rounded-full text-xs font-semibold ${wizard.complete ? 'primaryBG text-white' : 'bg-gray-300 text-black'}`}>
                    {wizard.number}
                </div>
                <span className="font-bold ml-2 text-gray-800" style={{ lineHeight: '20px' }}>
                    {wizard.title}
                </span>
            </div>
            <div>
                <p className="text-gray-600 text-sm">{wizard.text}</p>
            </div>
        </div>
    );
};

const Quote = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [quoteDetail, setQuoteDetail] = useState({});
    const [propertyValuation, setPropertyValuation] = useState(0);
    const [houseValuation, setHouseValuation] = useState(600000);
    const [houseStructureValuation, setHouseStructureValuation] = useState(450000);
    const [houseFurnishingValuation, setHouseFurnishingValuation] = useState(150000);

    const [proprtyGrowthRate, setProprtyGrowthRate] = useState(5.00);
    const [depreciationRate, setDepreciationRate] = useState(2.50);
    const [inflationRate, setInflationRate] = useState(2.50);
    const [investorSecuredCapitalReturnRate, setInvestorSecuredCapitalReturnRate] = useState(5.00);

    const [formData, setFormData] = useState({
        googleAddress: '',
        landTokenSymbol: '',
        propertySize: '',
        landSize: 600,
        leaseDuration: 20
    });

    const [propertyAddress, setPropertyAddress] = useState("");
    const [nestAgreementDuration, setNestAgreementDuration] = useState("");

    const [loadingModalVisible, setLoadingModalVisible] = useState(true);
    const [weGotTheHouseModalVisible, setWeGotTheHouseModalVisible] = useState(false);
    const [errorModalVisible, setErrorModalVisible] = useState(false);

    const [period, setPeriod] = useState('weekly');

    const calculateFee = () => {
        const annualFee = Number(quoteDetail.annualNestFee);
        return period === 'weekly' ? Math.ceil(annualFee / 52) : Math.ceil(annualFee / 12);
    };

    useEffect(() => {
        const getQuoteDetail = async () => {
            const searchParams = new URLSearchParams(location.search);
            const quoteID = searchParams.get('quoteID');
            if (quoteID) {
                const quoteDetailResponse = await postRequest("/getQuoteDetail", {
                    quoteID: quoteID
                });
                console.log(quoteDetailResponse);
                setQuoteDetail(quoteDetailResponse);

                setFormData({
                    googleAddress: quoteDetailResponse.propertyAddress,
                    nestAgreementDuration: quoteDetailResponse.nestAgreementDuration,
                    propertyPurchasePriceEstimate: quoteDetailResponse.propertyPurchasePriceEstimate
                });

                setPropertyValuation(quoteDetailResponse.propertyPurchasePriceEstimate);

                setLoadingModalVisible(false);
            } else {
                console.log('No quoteID found')
            }
        };

        getQuoteDetail();

    }, []);

    const weGotTheHouseClick = async () => {
        setLoadingModalVisible(true);
        const quoteFulfillmentResponse = await postRequestReturnAll("/createNest", {
            "quoteID": quoteDetail.quoteID,
            "propertyPurchasePrice": quoteDetail.propertyValuation
        });

        if (quoteFulfillmentResponse.status == 200 || quoteFulfillmentResponse.status == 201 || quoteFulfillmentResponse.status == 202) {
            setLoadingModalVisible(false);
            navigate(`/myNest`);
        } else {
            setLoadingModalVisible(false);
            setErrorModalVisible(true);
        }
    };

    const confirmHouseSale = async () => {
        setLoadingModalVisible(true);
        const quoteFulfillmentResponse = await postRequestReturnAll("/fulfillQuoteRequest", {
            "quoteID": quoteDetail.quoteID,
            "propertyAddress": quoteDetail.propertyAddress,
            "nestAgreementDuration": quoteDetail.nestAgreementDuration,
            "depreciationRate": depreciationRate,
            "inflationRate": inflationRate,
            "investorSecuredCapitalReturnRate": investorSecuredCapitalReturnRate,
            "initialHouseStructureValuation": houseStructureValuation,
            "initialHouseFurnishingValuation": houseFurnishingValuation,
            "initialLandValuation": propertyValuation - houseStructureValuation - houseFurnishingValuation,
            "initialOverbiddingValuation": quoteDetail.propertyPurchasePriceEstimate - propertyValuation
        });

        if (quoteFulfillmentResponse.status == 200 || quoteFulfillmentResponse.status == 201 || quoteFulfillmentResponse.status == 202) {
            setLoadingModalVisible(false);
            navigate(`/myNest`);
        } else {
            setLoadingModalVisible(false);
            setErrorModalVisible(true);
        }
    };

    return (
        <>
            <LoadingModal loadingModalVisible={loadingModalVisible} />
            <WeGotTheHouseModal modalVisible={weGotTheHouseModalVisible} modalActionFunction={() => { setLoadingModalVisible(false); navigate(`/myNest`); }} />
            <ErrorModal modalVisible={errorModalVisible} modalActionFunction={() => { setErrorModalVisible(false) }} />

            <div className="p-2">
                {quoteDetail.quoteID && (
                    <>
                        <div className="mb-3">
                            <h4><strong>Your nest agreement quote</strong>: {quoteDetail.propertyAddress.replace(', Australia', '')}</h4>
                            <p className="text-sm text-gray-900">
                                The following quote is valid for 30 days to secure the property. Please contact us if you require an extension on the quote.
                            </p>
                        </div>
                        <div className="flex flex-col lg:flex-row items-start lg:space-x-2">
                            <div className="w-full lg:w-1/4 mb-3 lg:mb-0">
                                <div className="box lg:mb-0">
                                    <div className="box-header p-2">
                                        <span className="box-header-text m-0 text-white">Nest agreement quote request</span>
                                    </div>
                                    <div className="flex flex-col p-3">
                                        <div className="flex flex-col">
                                            <span className="text-sm text-gray-900">
                                                <strong>Agreement duration: </strong> {quoteDetail.nestAgreementDuration} years
                                            </span>
                                        </div>
                                        <div className="flex flex-col">
                                            <span className="text-sm text-gray-900">
                                                <strong>Quote expiration: </strong> {convertUnixTimestampShort(quoteDetail.quoteExpirationTime)}
                                            </span>
                                        </div>
                                        <div className="flex flex-col">
                                            <label className="block text-sm font-semibold mb-2 text-red-700">
                                                Final Property Price
                                            </label>
                                            <div className="flex items-center border rounded w-48">
                                                <span className="px-2 text-gray-700">$</span>
                                                <input
                                                    className="w-full p-1.5 rounded text-gray-700 focus:outline-none text-right"
                                                    type="number"
                                                    name="propertyValuation"
                                                    step="5000"
                                                    min="0"
                                                    max="50000000"
                                                    value={propertyValuation}
                                                    onChange={e => setPropertyValuation(Number(e.target.value))}
                                                />
                                            </div>
                                            <div className="text-left pr-2 mt-1">
                                                <span className="text-xs text-gray-600 italic">
                                                    Value is: ${Number(propertyValuation).toLocaleString()}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full lg:w-1/4 mb-3 lg:mb-0">
                                <div className="box">
                                    <div className="box-header p-2">
                                        <span className="box-header-text m-0 text-white">Nest costs</span>
                                    </div>
                                    <div className="p-3 pb-0 flex flex-col">
                                        <div className="mb-4 text-center">
                                            <div className={`text-sm px-4 py-0.5 rounded bg-red-700 text-white mr-2 focus:outline-none`}>
                                                <span className="text-xl text-center font-bold">${Math.ceil(Number(quoteDetail.propertyLiquidationDeposit + quoteDetail.propertyStampDuty)).toLocaleString()}</span>
                                                <br/>
                                                <span className="text-sm text-center">up front</span>
                                            </div>
                                            <div className="text-left mt-2 mb-0">
                                                <ul className="list-disc pl-3 text-left text-sm">
                                                    <li><span><strong>Stamp duty: </strong>${Math.ceil(Number(quoteDetail.propertyStampDuty)).toLocaleString()}</span></li>
                                                    <li><span><strong>House purchase: </strong>${Math.ceil(Number(quoteDetail.propertyLiquidationDeposit)).toLocaleString()}</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <hr className=""/>
                                        <div className="mb-4 text-center">
                                            <div className={`text-sm px-4 py-0.5 rounded bg-red-700 text-white`}>
                                                <span className="text-xl font-bold text-center">${calculateFee().toLocaleString()}</span>
                                                <br/>
                                                <span className="text-sm text-center">{period}</span>
                                            </div>
                                            <div className="mt-2 text-center">
                                                <button
                                                    className={`text-sm px-4 py-0.5 rounded ${period === 'weekly' ? 'primaryBG' : 'bg-gray-700'} text-white mr-2 focus:outline-none`}
                                                    onClick={() => setPeriod('weekly')}>
                                                    Weekly
                                                </button>
                                                <button
                                                    className={`text-sm px-4 py-0.5 rounded ${period === 'monthly' ? 'primaryBG' : 'bg-gray-700'} text-white focus:outline-none`}
                                                    onClick={() => setPeriod('monthly')}>
                                                    Monthly
                                                </button>
                                            </div>
                                            <div className="text-left mt-2">
                                                <ul className="list-disc pl-3 text-left text-sm">
                                                    <li><span><strong>Fixed price </strong>for the life of nest agreement</span></li>
                                                    <li><span><strong>Flexibility </strong>to do what you want with the house</span></li>
                                                    <li><span>Able to rent house out</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full lg:w-1/2">
                                <div className="box">
                                    <div className="box-header p-2">
                                        <span className="box-header-text m-0 text-white">
                                            House purchasing process
                                        </span>
                                    </div>
                                    <div className="p-3">
                                        <div className="flex justify-center p-1">
                                            <div className="">
                                                {wizards.map((wizard, index) => (
                                                    <Step key={index} wizard={wizard} />
                                                ))}
                                            </div>
                                        </div>
                                        <div className="flex justify-center">
                                            <button className="primaryBG text-white p-2 rounded flex items-center focus:outline-none"
                                                onClick={() => {
                                                    setLoadingModalVisible(true);
                                                    weGotTheHouseClick();
                                                }}>
                                                <TrophyIcon className="h-5 w-5 mr-1 text-white" aria-hidden="true" />
                                                We got the house!
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default Quote;
