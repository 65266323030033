import React, { useState, useEffect } from "react";
import moment from 'moment';

const CountdownTimerText = ({ endTime }) => {
    const calculateTimeLeft = () => {
        const difference = endTime - moment().unix();
        let timeLeft = {};

        if (difference > 0) {
            const days = Math.floor(difference / (3600 * 24));
            const hours = Math.floor((difference % (3600 * 24)) / 3600);
            const minutes = Math.floor((difference % 3600) / 60);

            if (days > 365) {
                const years = Math.floor(days / 365);
                const months = Math.floor((days % 365) / 30);
                timeLeft = { years, months };
            } else if (days > 30) {
                const months = Math.floor(days / 30);
                const daysLeft = days % 30;
                timeLeft = { months, days: daysLeft };
            } else {
                timeLeft = { days, hours, minutes };
            }
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        if (endTime <= moment().unix()) {
            return;
        }

        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft]);

    const displayTimeLeft = () => {
        if ('years' in timeLeft) {
            return `${timeLeft.years} year${timeLeft.years > 1 ? 's' : ''} and ${timeLeft.months} month${timeLeft.months > 1 ? 's' : ''} to go`;
        } else if ('months' in timeLeft) {
            return `${timeLeft.months} month${timeLeft.months > 1 ? 's' : ''} and ${timeLeft.days} day${timeLeft.days > 1 ? 's' : ''} to go`;
        } else if ('days' in timeLeft) {
            return `${timeLeft.days} day${timeLeft.days > 1 ? 's' : ''}, ${timeLeft.hours} hour${timeLeft.hours > 1 ? 's' : ''}, and ${timeLeft.minutes} min${timeLeft.minutes > 1 ? 's' : ''} to go`;
        } else {
            return 'Final decision';
        }
    };

    const formattedEndTime = moment.unix(endTime).format('h:mmA DD MMM YYYY');

    // Conditional rendering logic to determine what to display based on time left
    const timerDisplay = endTime > moment().unix() ? (
        <span className="block font-bold mb-1">{displayTimeLeft()}</span>
    ) : (
        <span className="block font-bold mb-1">Final decision</span>
    );

    const endText = `Ends on ${formattedEndTime}`;
    const endedText = `Ended on ${formattedEndTime}`;
    const additionalDisplay = endTime > moment().unix() ? endText : endedText;

    return (
        <>
            {timerDisplay}
            <span className="block text-xs">{additionalDisplay}</span>
        </>
    );
};

export default CountdownTimerText;
