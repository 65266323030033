import React from 'react';
import { Link } from 'react-router-dom';
import { HomeIcon, CalculatorIcon, DocumentTextIcon, InformationCircleIcon, ChatBubbleLeftEllipsisIcon, CreditCardIcon, CogIcon, Squares2X2Icon, ArrowLeftCircleIcon } from '@heroicons/react/24/outline';

const getIconComponent = (text) => {
    const icons = {
        "Home": <Squares2X2Icon className="h-5 w-5" />,
        "Nest Overview": <InformationCircleIcon className="h-5 w-5" />,
        "Estimate price": <CalculatorIcon className="h-5 w-5" />,
        "Nest quotes": <DocumentTextIcon className="h-5 w-5" />,
        "My nest": <HomeIcon className="h-5 w-5" />,
        "My payments": <CreditCardIcon className="h-5 w-5" />,
        "Support": <ChatBubbleLeftEllipsisIcon className="h-5 w-5" />,
        "Blockchain": <CogIcon className="h-5 w-5" />,
    };
    return icons[text] || null;
};

const MenuItem = ({ text, link }) => {
    const IconComponent = getIconComponent(text);
    return (
        <li className="rounded transition-colors duration-300 hover:bg-primary">
            <Link to={link} className="flex items-center p-2 text-white no-underline hover:no-underline">
                {IconComponent && <span className="mr-2">{IconComponent}</span>}
                <span>{text}</span>
            </Link>
        </li>
    );
};

const Sidebar = ({ isOpen, toggleSidebar }) => {
    return (
        <div className={`${isOpen ? 'translate-x-0' : '-translate-x-full'} fixed top-16 left-0 bottom-0 w-52 bg-gray-800 text-white flex flex-col p-2 transition-transform duration-300 ease-in-out md:relative md:translate-x-0 z-10`}
            style={{ height: `calc(100vh - 4rem)` }}>
            <ul className="list-none p-0 m-0 flex-grow">
                <MenuItem text="Home" link="/" />
                <li className="p-2 text-xs text-gray-600 mt-3">WHAT IS A NEST</li>
                <MenuItem text="Nest Overview" link="/overview" />
                <li className="p-2 text-xs text-gray-600 mt-3">FIND A NEST</li>
                <MenuItem text="Estimate price" link="/estimate" />
                <MenuItem text="Nest quotes" link="/quotes" />
                <li className="p-2 text-xs text-gray-600 mt-3">MY NEST</li>
                <MenuItem text="My nest" link="/myNest" />
                <li className="p-2 text-xs text-gray-600 mt-3">SUPPORT</li>
                <MenuItem text="Support" link="/support" />
            </ul>
        </div>
    );
};

export default Sidebar;
