import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
// Import the HomeIcon from heroicons
import { CheckCircleIcon , ClockIcon } from '@heroicons/react/24/solid'; // or '@heroicons/react/solid' for solid icons



//Import helpers
import { postRequest, postRequestReturnAll } from "../../../helpers/apiHelper";
import { convertUnixTimestampShort }  from "../../../helpers/unixTimeConverter";


// Import components
import LoadingModal from "../../../components/LoadingModal";
import QuoteRequestConfirmationModal from "../../../components/QuoteRequestConfirmationModal";
import ErrorModal from "../../../components/ErrorModal";

// Define the stages of the process
const stages = [
    { key: 'offerAccepted', description: 'Verbal offer confirmed by potential homeowner', homeOwnerAction: '' },
    { key: 'nestAgreementSigned', description: 'Review and sign the nest agreement with the details of the property purchase.', homeOwnerAction: 'You will receive the agreement by email to review and sign.' },
    { key: 'depositPaid', description: '10% deposit paid into escrow. Nest agreement signed at this point', homeOwnerAction: '' },
    { key: 'saleContractSigned', description: 'The sale contract for the property has been verified by nest and signed', homeOwnerAction: 'The real estate agent will let you know when you need to sign the contract, it will confirm the settlement date.' },
    { key: 'propertyInspected', description: 'Property inspection has been passed, sale can proceed', homeOwnerAction: 'The real estate agent will arrange a building inspector, you need to pay for this.' },
    { key: 'propertyTitleReviewed', description: 'Settlement agent reviews the property title.', homeOwnerAction: '' },
    { key: 'pendingSettlement', description: 'All actions completed and waiting for settlement', homeOwnerAction: '' },
    { key: 'settled', description: 'Settlement has completed with property handover', homeOwnerAction: 'You collect the keys and move in!' }
  ];
  const StatusTable = ({ currentStatus }) => {
    // Determine if a stage is complete, current, or pending based on the current status
    const stageStatus = (stageKey) => {
      const currentIndex = stages.findIndex(stage => stage.key === currentStatus) + 1;
      const stageIndex = stages.findIndex(stage => stage.key === stageKey);
      return stageIndex < currentIndex ? 'complete' :
             stageIndex === currentIndex ? 'current' : 'pending';
    };
  
    // Convert camelCase text to Sentence case
    const formatText = (text) => {
      const result = text.replace(/([A-Z])/g, " $1");
      const final = result.charAt(0).toUpperCase() + result.slice(1);
      return final;
    };
  
    return (
        <div className="">
            <table className="min-w-full rounded-lg overflow-hidden shadow-md mb-4">
                <thead className="bg-gray-900 text-white">
                    <tr>
                        <th className="font-bold py-1 px-2 text-left border-b border-gray-300" style={{ width: '2%' }}></th>
                        <th className="font-bold py-1 px-2 text-left border-b border-gray-300" style={{ verticalAlign: 'top', width: '49%' }}>Step</th>
                        <th className="font-bold py-1 px-2 text-left border-b border-gray-300 break-words max-w-xs" style={{ verticalAlign: 'top', width: '49%' }}>You need to...</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {stages.map(stage => (
                        <tr key={stage.key}>
                            <td className="pl-2 py-2 whitespace-normal text-sm text-gray-500" style={{ verticalAlign: 'top', height: '80px', width: '2%' }}>
                                {stageStatus(stage.key) === 'complete' ? <CheckCircleIcon className="h-5 w-5 text-gray-900" /> :
                                stageStatus(stage.key) === 'current' ? <ClockIcon className="h-5 w-5 text-yellow-500" /> : null}
                            </td>
                            <td className="px-2 py-2 whitespace-normal text-sm text-gray-900" style={{ verticalAlign: 'top', height: '80px', width: '49%' }}>
                                <span><strong>{formatText(stage.key)}</strong></span>
                                <br/>
                                <span>{stage.description}</span>                
                            </td>
                            <td className="px-2 py-2 whitespace-normal text-sm text-gray-900" style={{ verticalAlign: 'top', height: '80px', width: '49%' }}>
                                {!stage.homeOwnerAction ? (
                                    <span className="italic text-gray-400">No action required</span>
                                ) : (
                                    <span>{stage.homeOwnerAction}</span>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>


    );
  };  
  

  
const MyNest = (props) => {
    const navigate = useNavigate();

    const [nest, setNest] = useState(false);
    
      
    const [loadingModalVisible, setLoadingModalVisible] = useState(true);
    const [quoteRequestConfirmationModalVisible, setQuoteRequestConfirmationModalVisible] = useState(false);
    const [errorModalVisible, setErrorModalVisible] = useState(false);

    const [nestPayments, setNestPayments] = useState([
        {
          "paymentID": "pay-1",
          "propertyAddress": "123 Example St, City 1",
          "nestID": "fcfe05c2-126b-400c-acb1-e7ce51b3d124",
          "paymentAmount": 556,
          "paymentDate": 1713065522,
          "paymentCleared": "true",
          "paymentMethod": "Credit Card",
          "receiptNumber": "RCPT-1001"
        },
        {
          "paymentID": "pay-2",
          "propertyAddress": "123 Example St, City 2",
          "nestID": "fcfe05c2-126b-400c-acb1-e7ce51b3d124",
          "paymentAmount": 556,
          "paymentDate": 1712460722,
          "paymentCleared": "true",
          "paymentMethod": "Credit Card",
          "receiptNumber": "RCPT-1002"
        },
        {
          "paymentID": "pay-3",
          "propertyAddress": "123 Example St, City 3",
          "nestID": "fcfe05c2-126b-400c-acb1-e7ce51b3d124",
          "paymentAmount": 556,
          "paymentDate": 1711855922,
          "paymentCleared": "true",
          "paymentMethod": "Credit Card",
          "receiptNumber": "RCPT-1003"
        },
        {
          "paymentID": "pay-4",
          "propertyAddress": "123 Example St, City 4",
          "nestID": "fcfe05c2-126b-400c-acb1-e7ce51b3d124",
          "paymentAmount": 556,
          "paymentDate": 1711251122,
          "paymentCleared": "true",
          "paymentMethod": "Credit Card",
          "receiptNumber": "RCPT-1004"
        },
        {
          "paymentID": "pay-5",
          "propertyAddress": "123 Example St, City 5",
          "nestID": "fcfe05c2-126b-400c-acb1-e7ce51b3d124",
          "paymentAmount": 556,
          "paymentDate": 1710646322,
          "paymentCleared": "true",
          "paymentMethod": "Credit Card",
          "receiptNumber": "RCPT-1005"
        }
      ])
    

    const [displayEstimateContent, setDisplayEstimateContent] = useState(false);

    
    
    useEffect(() => {
        
        const getNest = async () => {
            
            const nestResponse = await postRequest("/getNestForUser", {
                
            });
            console.log(nestResponse);
            setLoadingModalVisible(false);
            setNest(nestResponse);
        };

        getNest();
        
    }, []);

    const getPaymentStatus = () => {
        return(
            
            <span className={`inline-block text-sm px-4 py-0.5 rounded bg-gray-900 text-white ml-2 focus:outline-none flex items-center justify-center `}>
                <CheckCircleIcon className="h-4 w-4 text-white-500 mr-2" aria-hidden="true" />
                Up to date
            </span>
        )
    }

    
    const calculateFee = () => {
        const annualFee = Number(nest.annualNestFee);
        return nest.paymentPreference === 'Monthly' ? Math.ceil((annualFee / 12)).toLocaleString() : Math.ceil((annualFee / 52).toLocaleString());
    };
       


    return (
        <>
            <LoadingModal loadingModalVisible={loadingModalVisible} />
            
            <QuoteRequestConfirmationModal modalVisible={quoteRequestConfirmationModalVisible} modalActionFunction={()=> {setQuoteRequestConfirmationModalVisible(false); navigate(`/quotes`);}} />
            <ErrorModal modalVisible={errorModalVisible} modalActionFunction={()=> {setErrorModalVisible(false)}} />

            {nest.nestStatus && (
                <>
                {nest.nestStatus !== 'settled' && (                
                    <div className="p-2">
                        <div className="mb-3">
                            <h4>My Nest setup</h4>
                            <p className="text-sm text-gray-900">
                                We are excited to move you into your nest! The following steps now need to be taken and we will update you here along with your settlement agent. Please reach out to us if you have any questions 😊
                            </p>
                        </div>
    
                        <div className="my-3">

                        </div>
                        <StatusTable currentStatus={nest.nestStatus} />
                    </div>
                )}
    
                {nest.nestStatus == 'settled' && (                
                    <div className="p-2">
                        <div className="mb-3">
                            <h4>My Nest</h4>
                            <p className="text-sm text-gray-900">
                                Manage payments, insurances and review your nest agreement below. Please reach out to us if you have any questions 😊
                            </p>
                        </div>

                        <div className="flex items-start">
                            <div className="box w-1/2 mr-2">
                                <div className="box-header p-2">
                                    <span className="box-header-text m-0 text-white">Property details</span>
    
                                </div>
                                <div className="p-3">
    
                                    <span className="text-sm text-gray-900">
                                        <strong>Property address: </strong> {nest.propertyAddress.replace('WA, Australia', '')}
                                    </span>
                                    <br/>
                                    <span className="text-sm text-gray-900">
                                        <strong>Nest agreement start date: </strong> {convertUnixTimestampShort(nest.nestAgreementStartDate)}
                                    </span>
                                    <br/>
                                    <span className="text-sm text-gray-900">
                                        <strong>Nest agreement end date: </strong> {convertUnixTimestampShort(nest.nestAgreementEndDate)}
                                    </span>
                                    <br/>
                                    <span className="text-sm text-gray-900">
                                        <strong>Proposed agreement duration: </strong> {nest.nestAgreementDuration} years
                                    </span>
                                    <br/>
    
                                </div>
                            </div>
                            <div className="box w-1/2 ml-2">
                                <div className="box-header p-2">
                                    <span className="box-header-text m-0 text-white">Payment details</span>

                                </div>
                                <div className="p-3">
                                    <div className="flex items-start">
                                        <div className="w-1/2 mr-2">
                                            
                                            <span className="text-xl font-bold text-center">
                                                ${Math.ceil(Number(calculateFee())).toLocaleString()} 
                                            </span>
                                            <span className="text-sm text-center">{nest.paymentPreference}</span>
                                        </div>

                                        <div className="w-1/2 ml-2">
                                            <div className="flex flex-row items-center">
                                                <span className="text-sm text-gray-900">
                                                    <strong>Payment status:</strong>
                                                </span>
                                                {getPaymentStatus()}
                                            </div>
                                            <span className="text-sm text-gray-900">
                                                <strong>Next payment date:</strong> 17 Jun 2024
                                            </span>
                                        </div>
                                    </div>
                                    
                                    <table className="w-full rounded-lg overflow-hidden mt-3">
                                        <thead className="bg-gray-300 text-black">
                                            <tr>
                                                <th className="font-bold py-1 px-2 text-left border-b border-gray-300">Payment date</th>
                                                <th className="font-bold py-1 px-2 text-right border-b border-gray-300">Amount</th>
                                                <th className="font-bold py-1 px-2 text-left border-b border-gray-300">Method</th>
                                                <th className="font-bold py-1 px-2 text-left border-b border-gray-300">Status</th>
                                                <th className="font-bold py-1 px-2 text-left border-b border-gray-300">Receipt</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {nestPayments.map((payment) => (
                                                <tr className="bg-white cursor-pointer">
                                                    <td className="border-b border-gray-200 py-1 px-2 text-left text-sm">{convertUnixTimestampShort(payment.paymentDate)}</td>
                                                    <td className="border-b border-gray-200 py-1 px-2 text-right text-sm">${new Intl.NumberFormat('en-AU', { style: 'decimal', maximumFractionDigits: 0 }).format(payment.paymentAmount)}</td>
                                                    <td className="border-b border-gray-200 py-1 px-2 text-left text-sm">{payment.paymentMethod}</td>
                                                    <td className="border-b border-gray-200 py-1 px-2 text-left text-sm">{payment.paymentCleared ? "Cleared" : "Unsuccessul"}</td>
                                                    <td className="border-b border-gray-200 py-1 px-2 text-left text-sm">{payment.receiptNumber}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                )}
                </>
            )}
            
            
        </>
    );
};


export default MyNest;
