import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import Logo from "./../../../assets/images/nest logo.png";
import { useNavigate, Link } from "react-router-dom";
import { UserIcon } from '@heroicons/react/24/outline';
import LoadingModal from "../../../components/LoadingModal";

const SignIn = () => {
  let navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      return;
    }
    setIsLoading(true);
    try {
      const auth = getAuth();
      const userData = await signInWithEmailAndPassword(auth, email, password);
      localStorage.setItem("refreshToken", userData.user.stsTokenManager.refreshToken);
      localStorage.setItem("uid", userData.user.uid);
      localStorage.setItem("displayName", userData.user.displayName);
      localStorage.setItem("email", userData.user.email);

      if (userData.user.accessToken) {
        navigate('/');
      }
    } catch (error) {
      let errorMessage;
      switch (error.code) {
        case "auth/invalid-email":
          errorMessage = "Enter a valid email address";
          break;
        case "auth/wrong-password":
          errorMessage = "Enter a valid password";
          break;
        case "auth/user-not-found":
          errorMessage = "Invalid email or password";
          break;
        default:
          errorMessage = error.message || "Error in Login";
          break;
      }
      setError(errorMessage);
    }
    setIsLoading(false);
  };

	return (
		<>
		<div
			className="min-h-screen text-white relative"
			style={{
			backgroundImage: `url('/signinBG.jpg')`, 
			backgroundSize: 'cover',
			backgroundPosition: 'center', // This centers the image both vertically and horizontally
			}}
		>

			<LoadingModal loadingModalVisible={isLoading} /> 
			

			<div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-60 z-0">
                    <div className="min-h-screen flex items-stretch justify-end z-10">
                        <div className="flex flex-col justify-center items-center bg-gray-50 px-8 py-3"
                            style={{ borderRadius: '0' }}
                        >
							<div className="text-center mb-4">
								<img className="mx-auto" src={Logo} alt="" width="200" />
							</div>
                            <form onSubmit={handleSubmit} autoComplete="off" className="w-full">
								<div className="mb-2">
								<label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
									Email Address
								</label>
								<input
									className="appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline rounded"
									id="email"
									type="email"
									placeholder="Enter Email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
								</div>

								<div className="mb-4">
								<label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
									Password
								</label>
								<input
									className="appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline rounded"
									id="password"
									type="password"
									placeholder="Enter Password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
								</div>

								<div className="mb-2">
								<button
									className="primaryBG text-white font-bold py-2 px-4 w-full focus:outline-none focus:shadow-outline rounded"
									type="submit"
								>
									<div className="flex items-center justify-center">
									<UserIcon className="h-5 w-5 mr-2" /> Login
									</div>
								</button>
								</div>

								{error && (
								<p className="text-red-500 text-xs italic text-center">{error}</p>
								)}

								<div className="text-center">
								<Link
									to="/forgot-password"
									className="inline-block align-baseline font-bold text-sm text-gray-700 hover:no-underline hover:text-gray-900"
								>
									Forgot Password?
								</Link>
								</div>
							</form>
						</div>

						</div>
					
				</div>
			</div>
			
                  
            <style jsx>{`
                @media (min-width: 769px) {
                    .flex.flex-col {
                        width: 400px; // Fixed width for non-mobile devices
                    }
                }
                @media (max-width: 768px) {
                    .flex.flex-col {
                        width: 100%; // Ensure full width on mobile devices
                    }
                }
            `}</style>
		</>
	);
};

export default SignIn;
