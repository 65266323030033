//Import other helpers
import { postRequestUnauthenticated } from "./apiHelper";

export const toDollarFormat = (number) => {
  // Round up to the nearest dollar
  const roundedNumber = Math.ceil(number);

  // Convert to dollar format with commas
  return '$' + roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export const calculateInvestorStampDuty = (propertyAddress, purchasePrice) => {
    let duty = 0;
    
    // Helper function to calculate additional duty for given ranges
    const calculateAdditionalDuty = (price, rate, threshold) => (price - threshold) * (rate / 100);
    
    if (propertyAddress.includes('NSW')) {
        if (purchasePrice > 3131000) {
            duty = 157080 + calculateAdditionalDuty(purchasePrice, 7, 3131000);
        } else if (purchasePrice > 1043000) {
            duty = 42240 + calculateAdditionalDuty(purchasePrice, 5.5, 1043000);
        } else if (purchasePrice > 313000) {
            duty = 9390 + calculateAdditionalDuty(purchasePrice, 4.5, 313000);
        } else if (purchasePrice > 83000) {
            duty = 1340 + calculateAdditionalDuty(purchasePrice, 3.5, 83000);
        }
    } else if (propertyAddress.includes('VIC')) {
        if (purchasePrice > 2000000) {
            duty = 110000 + calculateAdditionalDuty(purchasePrice, 6.5, 2000000);
        } else if (purchasePrice > 960000) {
            duty = purchasePrice * 0.055;
        } else if (purchasePrice > 130000) {
            duty = 2870 + calculateAdditionalDuty(purchasePrice, 6, 130000);
        }
    } else if (propertyAddress.includes('QLD')) {
        if (purchasePrice > 1000000) {
            duty = 38025 + calculateAdditionalDuty(purchasePrice, 5.75, 1000000);
        } else if (purchasePrice > 540000) {
            duty = 17325 + calculateAdditionalDuty(purchasePrice, 4.5, 540000);
        } else if (purchasePrice > 75000) {
            duty = 1050 + calculateAdditionalDuty(purchasePrice, 3.5, 75000);
        }
    } else if (propertyAddress.includes('SA')) {
        if (purchasePrice > 500000) {
            duty = 21330 + calculateAdditionalDuty(purchasePrice, 5.5, 500000);
        } else if (purchasePrice > 300000) {
            duty = 11330 + calculateAdditionalDuty(purchasePrice, 5, 300000);
        } else if (purchasePrice > 250000) {
            duty = 8955 + calculateAdditionalDuty(purchasePrice, 4.75, 250000);
        }
    } else if (propertyAddress.includes('TAS')) {
        if (purchasePrice > 725000) {
            duty = 27810 + calculateAdditionalDuty(purchasePrice, 4.5, 725000);
        } else if (purchasePrice > 375000) {
            duty = 12935 + calculateAdditionalDuty(purchasePrice, 4.25, 375000);
        } else if (purchasePrice > 200000) {
            duty = 5935 + calculateAdditionalDuty(purchasePrice, 4, 200000);
        }
    } else if (propertyAddress.includes('WA')) {
        if (purchasePrice > 500001) {
            duty = 19665 + calculateAdditionalDuty(purchasePrice, 5.15, 500001);
        } else if (purchasePrice > 250001) {
            duty = 7790 + calculateAdditionalDuty(purchasePrice, 4.75, 250001);
        } else if (purchasePrice > 100001) {
            duty = 2090 + calculateAdditionalDuty(purchasePrice, 3.8, 100001);
        }
    } else if (propertyAddress.includes('NT')) {
        // Using the formula provided for properties up to $550,000
        if (purchasePrice <= 550000) {
            let V = purchasePrice / 1000;
            duty = (0.06571441 * V ** 2) + 15 * V;
        } else if (purchasePrice > 3000000) {
            duty = (purchasePrice - 3000000) * 0.0595 + 177000; // Assuming a flat rate for simplicity
        } else if (purchasePrice > 525001) {
            duty = purchasePrice * 0.0495; // Simplified for example
        }
    } else if (propertyAddress.includes('ACT')) {
        if (purchasePrice > 1455000) {
            duty = 36950 + calculateAdditionalDuty(purchasePrice, 6.4, 1000001);
        } else if (purchasePrice > 1000001) {
            duty = 22200 + calculateAdditionalDuty(purchasePrice, 5.9, 750001);
        } else if (purchasePrice > 500001) {
            duty = 11400 + calculateAdditionalDuty(purchasePrice, 4.32, 500001);
        } else if (purchasePrice > 300001) {
            duty = 4600 + calculateAdditionalDuty(purchasePrice, 3.4, 300001);
        }
    } else {
        duty = 0;
    }
    
    return Math.round(duty); // Round the result for cleanliness
}
      


export const calculateAnnualLandFee = async (propertyAddress, nestAgreementDuration) => {
    try{
        
        const response = await postRequestUnauthenticated("/getAnnualNestLandFeeEstimate", {
            "propertyAddress": propertyAddress,
            "agreementDuration" : nestAgreementDuration
        });    

        let totalAnnualPayment = response.totalAnnualPayment;
                    
        return totalAnnualPayment;

    } catch(e) {
        alert('Error calculating land fee, likely failed request for house valuation.' + e)
    }

}