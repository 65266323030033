import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { ArrowLeftCircleIcon, CalculatorIcon, UserPlusIcon } from '@heroicons/react/24/solid'; 

import { postRequestReturnAll } from "../../../helpers/apiHelper";
import { toDollarFormat, calculateAnnualLandFee, calculateInvestorStampDuty } from "../../../helpers/calculatorHelper";

import LoadingModal from "../../../components/LoadingModal";
import LoadingEstimateModal from "../../../components/LoadingEstimateModal";
import QuoteRequestConfirmationModal from "../../../components/QuoteRequestConfirmationModal";
import ErrorModal from "../../../components/ErrorModal";

const Estimate = (props) => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        googleAddress: '',
        landTokenSymbol: '',
        propertySize: '',
        landSize: 600,
        leaseDuration: 20
    });
    
    const [propertyAddress, setPropertyAddress] = useState(""); 
    const [nestAgreementDuration, setNestAgreementDuration] = useState("");
    const [propertyPurchasePrice, setPropertyPurchasePrice] = useState("");
    
    const [houseDeposit, setHouseDeposit] = useState(0);
    const [govtStampDuty, setGovtStampDuty] = useState(0);
    const [overbidding, setOverbidding] = useState(0);
    const [totalDeposit, setTotalDeposit] = useState(0);

    const [annualLandFee, setAnnualLandFee] = useState(0);
    const [administrationFee, setAdministrationFee] = useState(0);
    const [totalAnnualFee, setTotalAnnualFee] = useState(0);
    
    const [streetViewUrl, setStreetViewUrl] = useState('');
    const [streetViewLink, setStreetViewLink] = useState('');
    const [aerialViewUrl, setAerialViewUrl] = useState('');
    const [aerialViewLink, setAerialViewLink] = useState('');
      
    const [loadingModalVisible, setLoadingModalVisible] = useState(false);
    const [loadingEstimateModalVisible, setLoadingEstimateModalVisible] = useState(false);
    const [quoteRequestConfirmationModalVisible, setQuoteRequestConfirmationModalVisible] = useState(false);
    const [errorModalVisible, setErrorModalVisible] = useState(false);

    const [mortgageRate, setMortgageRate] = useState(6.00);
    const [displayEstimateContent, setDisplayEstimateContent] = useState(false);

    const getStreetViewImageURL = (lat, lng) => {
        const size = "600x300"; 
        const apiKey = "AIzaSyAMmpLKpbMkICzu7ht1Flfdq6-EtZAK9iI"; 
        return `https://maps.googleapis.com/maps/api/streetview?size=${size}&location=${lat},${lng}&key=${apiKey}`;
    };
    
    const getStreetViewLink = (lat, lng) => {
        return `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lat},${lng}`;
    };

    const getMapImageURL = (lat, lng) => {
        const size = "600x300"; 
        const apiKey = "AIzaSyAMmpLKpbMkICzu7ht1Flfdq6-EtZAK9iI"; 
        return `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=20&size=${size}&maptype=roadmap&key=${apiKey}`;
    };
    
    const getAerialViewLink = (lat, lng) => {
        return `https://www.google.com/maps/@${lat},${lng},20z`;
    };

    const setPropertyAddressAndImages = async (address) => {    
        geocodeByAddress(address)
        .then(results => {
            console.log(results[0]);
            setFormData({ ...formData, googleAddress: address }); 
            return getLatLng(results[0]);
        })
        .then(({ lat, lng }) => {
            const streetViewLink = getStreetViewLink(lat, lng); 
            const streetViewImageUrl = getStreetViewImageURL(lat, lng); 
            const mapImageUrl = getMapImageURL(lat, lng); 
            const mapAerialViewLink = getAerialViewLink(lat, lng); 
            setStreetViewUrl(streetViewImageUrl); 
            setStreetViewLink(streetViewLink);
            setAerialViewUrl(mapImageUrl);
            setAerialViewLink(mapAerialViewLink);
        })
        .catch(error => console.error('Error', error));
    };

    const getEstimate = async() => {
        let annualLandFee = await calculateAnnualLandFee(propertyAddress,nestAgreementDuration);
        let stampDuty = await calculateInvestorStampDuty(propertyAddress,propertyPurchasePrice);
        let liquidationDeposit = propertyPurchasePrice * 0.03;

        setTimeout(function() {
            console.log(annualLandFee);
            setHouseDeposit(liquidationDeposit);
            setGovtStampDuty(stampDuty);
            setTotalDeposit(liquidationDeposit + stampDuty);
            setAnnualLandFee(Number(annualLandFee));
            setAdministrationFee(1500);
            setTotalAnnualFee(annualLandFee + 1500);
            setDisplayEstimateContent(true);
            setLoadingEstimateModalVisible(false);
        }, 10000);
    };

    const requestQuote = async() => {
        
        setLoadingModalVisible(true);
        
        const requestQuoteResponse = await postRequestReturnAll("/requestQuote", {
            "propertyAddress": propertyAddress,
            "nestAgreementDuration": nestAgreementDuration,
            "propertyPurchasePriceEstimate": propertyPurchasePrice
        });
  
        if(requestQuoteResponse.status == 200 || requestQuoteResponse.status == 201 || requestQuoteResponse.status == 202) {
            setLoadingModalVisible(false);
            setQuoteRequestConfirmationModalVisible(true);  
        } else {
            setLoadingModalVisible(false);
            setErrorModalVisible(true);
        }
    };

    return (
        <>
            <LoadingModal loadingModalVisible={loadingModalVisible} />
            <LoadingEstimateModal loadingModalVisible={loadingEstimateModalVisible} />
            <QuoteRequestConfirmationModal modalVisible={quoteRequestConfirmationModalVisible} modalActionFunction={() => { setQuoteRequestConfirmationModalVisible(false); navigate(`/quotes`); }} />
            <ErrorModal modalVisible={errorModalVisible} modalActionFunction={() => { setErrorModalVisible(false); }} />

            <div className="p-2">
                <div className="mb-3">
                    <h4>Estimate nest agreement price</h4>
                    <p className="text-sm text-gray-900">
                        With a nest agreement, you choose the property you want, we then provide price estimates for you to own the house for as long as you want.
                    </p>
                </div>

                <div className="flex flex-col md:flex-row">
                    <div className="md:w-1/2 md:mr-2 mb-2 md:mb-0">
                        <PlacesAutocomplete
                            value={propertyAddress}
                            onChange={setPropertyAddress}
                            onSelect={(selectedAddress) => {
                                setPropertyAddress(selectedAddress);
                                setFormData({ ...formData, googleAddress: selectedAddress });
                                setPropertyAddressAndImages(selectedAddress);
                            }}
                            searchOptions={{
                                types: ['address'],
                                componentRestrictions: { country: 'au' }
                            }}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div className="relative">
                                    <label className="block text-sm font-semibold mb-2">
                                        Property address
                                    </label>
                                    <input
                                        {...getInputProps({
                                            placeholder: 'Search a property address',
                                            className: 'w-full p-1.5 border rounded-md focus:outline-none',
                                        })}
                                    />
                                    <div className="absolute z-10 w-full bg-white mt-1 rounded-md shadow-lg">
                                        {loading && <div className="p-1 text-center text-sm text-gray-700">Loading...</div>}
                                        {!loading && suggestions.map(suggestion => {
                                            const className = suggestion.active
                                                ? 'p-1.5 text-sm text-gray-900 primaryBG rounded-md cursor-pointer'
                                                : 'p-1.5 text-sm text-gray-900 hover:primaryBG rounded-md cursor-pointer';
                                            const style = {};
                                            return (
                                                <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>

                        <div className="flex flex-wrap -mx-2">
                            <div className="pt-2 w-1/2 px-2">
                                <label className="block text-sm font-semibold mb-2">How many years do you want to stay here?</label>
                                <select
                                    className="w-full p-1.5 border rounded border-gray-700 focus:outline-none text-right"
                                    name="leaseDuration"
                                    value={nestAgreementDuration}
                                    onChange={e => setNestAgreementDuration(e.target.value)}
                                >
                                    <option key={""} value={""}></option>
                                    {[...Array(21).keys()].map(year => (
                                        <option key={year + 200} value={year + 20}>{year + 20} years</option>
                                    ))}
                                </select>
                            </div>

                            <div className="pt-2 w-1/2 px-2">
                                <label className="block text-sm font-semibold mb-2">
                                    What do you think the property will go for?
                                </label>
                                <div className="flex items-center border rounded">
                                    <span className="px-2 text-gray-700">$</span>
                                    <input
                                        className="w-full p-1.5 rounded text-gray-700 focus:outline-none text-right"
                                        type="number"
                                        name="estimatedPrice"
                                        step="5000"
                                        min="0"
                                        value={propertyPurchasePrice}
                                        onChange={e => setPropertyPurchasePrice(Number(e.target.value))}
                                    />
                                </div>
                                <div className="text-right pr-2">
                                    <span className="text-xs text-gray-600 italic text-right">
                                        Current value is: ${Number(propertyPurchasePrice).toLocaleString()}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="w-full flex mt-2">
                            {streetViewUrl && (
                                <div className="w-1/2 mr-2">
                                    <label className="block text-sm font-semibold mb-2 text-center">Street View</label>
                                    <img
                                        src={streetViewUrl}
                                        alt="Street View"
                                        className="w-full aspect-video rounded-md cursor-pointer"
                                        onClick={() => window.open(streetViewLink, '_blank')}
                                    />
                                </div>
                            )}

                            {aerialViewUrl && (
                                <div className="w-1/2 ml-2">
                                    <label className="block text-sm font-semibold mb-2 text-center">Map View</label>
                                    <img
                                        src={aerialViewUrl}
                                        alt="Map View"
                                        className="w-full aspect-video rounded-md cursor-pointer"
                                        onClick={() => window.open(aerialViewLink, '_blank')}
                                    />
                                </div>
                            )}
                        </div>

                        <div className="flex justify-center mt-3">
                            <button className="primaryBG text-white p-2 rounded flex items-center focus:outline-none"
                                onClick={() => {
                                    setLoadingEstimateModalVisible(true);
                                    getEstimate();
                                }}>
                                <CalculatorIcon className="h-5 w-5 mr-1 text-white" aria-hidden="true" />
                                Get estimate
                            </button>
                        </div>
                    </div>

                    <div className="md:w-1/2 md:ml-2">
                        {displayEstimateContent &&
                            <div className="box">
                                <div className="box-header p-2">
                                    <span className="box-header-text m-0 text-white">
                                        Nest agreement estimate
                                    </span>
                                </div>
                                <div className="p-3">
                                    <p className="text-sm text-gray-900">
                                        <strong>PLEASE NOTE: This is an informal estimate only</strong> <br />
                                        A formal quote is required to proceed with a nest agreement. Please create an account to get a formal quote.
                                    </p>

                                    <div className="flex text-sm text-gray-900">
                                        <div className="flex-1 pr-2">
                                            <div className="uppercase tracking-wide text-sm text-orange-500 font-semibold">Up front cost</div>
                                            <div className="flex justify-between border-b border-gray-200 py-1">
                                                <span>House deposit:</span>
                                                <span className="text-right">{toDollarFormat(propertyPurchasePrice * 0.03)}</span>
                                            </div>
                                            <div className="flex justify-between border-b border-gray-200 py-1">
                                                <span>Govt. stamp duty:</span>
                                                <span className="text-right">{toDollarFormat(govtStampDuty)}</span>
                                            </div>
                                            <div className="flex justify-between border-b border-gray-200 py-1">
                                                <span>Overbidding:</span>
                                                <span className="text-right">{toDollarFormat(overbidding)}</span>
                                            </div>
                                            <div className="flex justify-between font-semibold py-2">
                                                <span>Total</span>
                                                <span className="text-right">{toDollarFormat(totalDeposit)}</span>
                                            </div>
                                        </div>

                                        <div className="flex-1 pl-2">
                                            <div className="uppercase tracking-wide text-sm text-orange-500 font-semibold text-right">Nest land fee</div>
                                            <div className="py-2 text-right">
                                                <span className="text-right"><strong>~{toDollarFormat((annualLandFee * 0.95 + administrationFee) / 52)} to {toDollarFormat((annualLandFee * 1.15 + administrationFee) / 52)} weekly</strong></span>
                                                <br />
                                                <span className="text-right text-xs">Equivalent to</span>
                                                <br />
                                                <span className="text-right text-xs">~{toDollarFormat((annualLandFee * 0.95 + administrationFee) / 12)} to {toDollarFormat((annualLandFee * 1.15 + administrationFee) / 12)} monthly</span>
                                                <br />
                                                <span className="text-right text-xs">~{toDollarFormat((annualLandFee * 0.95 + administrationFee) / 1)} to {toDollarFormat((annualLandFee * 1.15 + administrationFee) / 1)} p.a.</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex justify-center mt-3">
                                        <button className="bg-gray-900 text-white p-2 rounded flex items-center focus:outline-none "                            
                                            onClick={() => {
                                                requestQuote();
                                            }}>
                                            <CalculatorIcon className="h-5 w-5 mr-1 text-white" aria-hidden="true" />
                                            Get formal quote
                                        </button>
                                    </div>
                                </div>
                            </div>}

                        {displayEstimateContent &&
                            <div className="box mt-2 mb-4">
                                <div className="box-header bg-red-800 p-2">
                                    <span className="box-header-text m-0 text-white">
                                        Mortgage comparison
                                    </span>
                                </div>
                                <div className="p-3">
                                    <div className="pb-3 flex">
                                        <div className="w-3/5 pr-2">
                                            <span className="text-sm text-gray-900">
                                                <strong>Mortgage comparison</strong><br />
                                                Compares purchasing this house with a 30 year mortgage.
                                            </span>
                                        </div>
                                        <div className="w-2/5">
                                            <span className="text-sm text-gray-900">
                                                <strong>Mortgage rate</strong><br />
                                            </span>
                                            <div className="flex items-center border rounded">
                                                <input
                                                    className="w-full p-1.5 pr-0 rounded text-gray-700 focus:outline-none text-right"
                                                    type="number"
                                                    name="mortgageRate"
                                                    step="0.1"
                                                    min="0"
                                                    value={mortgageRate}
                                                    onChange={e => setMortgageRate(Number(e.target.value))}
                                                />
                                                <span className="pr-2 text-gray-700">%</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex text-sm text-gray-900">
                                        <div className="flex-1 pr-2">
                                            <div className="uppercase tracking-wide text-sm text-orange-500 font-semibold">Upfront costs</div>
                                            <div className="flex justify-between border-b border-gray-200 py-1">
                                                <span>20% deposit:</span>
                                                <span className="text-right">{toDollarFormat(propertyPurchasePrice * 0.20)}</span>
                                            </div>
                                            <div className="flex justify-between border-b border-gray-200 py-1">
                                                <span>Govt. stamp duty:</span>
                                                <span className="text-right">{toDollarFormat(govtStampDuty)}</span>
                                            </div>
                                            <div className="flex justify-between font-semibold py-2">
                                                <span>Total</span>
                                                <span className="text-right">{toDollarFormat(propertyPurchasePrice * 0.20 + govtStampDuty)}</span>
                                            </div>
                                        </div>

                                        <div className="flex-1 pl-2">
                                            <div className="uppercase tracking-wide text-sm text-orange-500 font-semibold">First year costs</div>
                                            <div className="flex justify-between border-b border-gray-200 py-1">
                                                <span>Interest:</span>
                                                <span className="text-right">~{toDollarFormat((propertyPurchasePrice * 0.8 * mortgageRate / 100))}</span>
                                            </div>
                                            <div className="flex justify-between border-b border-gray-200 py-1">
                                                <span>Repayment:</span>
                                                <span className="text-right">~{toDollarFormat((propertyPurchasePrice * 0.8 / 30))}</span>
                                            </div>
                                            <div className="flex justify-between font-semibold py-2">
                                                <span>Total</span>
                                                <span className="text-right">~{toDollarFormat((propertyPurchasePrice * 0.8 * mortgageRate / 100 + propertyPurchasePrice * 0.8 / 30))}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Estimate;
