import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    AcademicCapIcon,     
    DocumentCheckIcon,
    HomeIcon, 
    ChartBarIcon, 
    EyeIcon, 
    ReceiptRefundIcon,
    HeartIcon
} from '@heroicons/react/24/outline';

const Overview = (props) => {
    const navigate = useNavigate();

    return (
        <>        
            <div className="p-2">
                <div className="flex justify-center">
                    <div className="text-center">
                        <h3 className="font-bold">A new way to own a home</h3>
                        
                        <div className="text-left w-full px-0">
                            <p className="mb-2">
                                A couple find a property they want to live in long term for 30 years, they do not have a sufficient deposit, driven by the cost of land. Under a nest agreement:
                            </p>
                            <ul className="list-disc pl-4 mb-4">
                                <li><span className="font-bold text-blue-700">Investor</span> provides the capital to purchase the property, with a caveat that they receive a fixed annual payment and the proceeds from the sale of the property in 30 years' time. This agreement is structured as a call option agreement with $0 exercise price.</li>
                                <li><span className="font-bold text-orange-600">Homeowner</span> purchases the property with the capital from the investor.</li>
                                <li><span className="font-bold">Property title:</span> In the name of the homeowner but caveated with the call option agreement, loan and liquidation deposit.</li>
                                <li><span className="font-bold text-orange-600">Homeowner</span> pays for all property costs (stamp duty, maintenance, insurance, taxes, sale costs at end of term)</li>
                                <li><span className="font-bold text-orange-600">Homeowner</span> pays an annual fee for the house</li>
                                <li><span className="font-bold text-blue-700">Investor</span> pays nothing for duration of the agreement term</li>
                                <li><span className="font-bold text-orange-600">Homeowner</span> owns nothing at end of agreement term</li>
                                <li><span className="font-bold text-blue-700">Investor</span> receives full proceeds from property sale</li>
                                <li>The house and all furnishings are expected to be <span className="font-bold">fully depreciated</span> by the end of the term.</li>
                            </ul>

                                <div className="border border-orange-600 p-2 rounded-lg mb-2">
                                    <h3 className="text-lg font-bold mb-2">Benefits to <span className="text-orange-600">Homeowners</span></h3>
                                    <ul className="list-disc pl-4">
                                    <li>Significantly less debt than owner/occupied dwelling</li>
                                    <li>Flexibility of property modifications (excl. structural extensions)</li>
                                    <li>No rental inspections, pets allowed etc.</li>
                                    <li>Make voluntary super contributions with higher returns</li>
                                    <li>Invest in equities with higher returns and ability to diversify</li>
                                    <li>Ability for parents to contribute as investors</li>
                                    </ul>
                                </div>
                                <div className="border border-blue-700 p-2 rounded-lg">
                                    <h3 className="text-lg font-bold mb-2">Benefits to <span className="text-blue-700">Investors</span></h3>
                                    <ul className="list-disc pl-4">
                                    <li>Exposure to property growth, without any costs/depreciation</li>
                                    <li>No land tax</li>
                                    <li>Leveraged exposure through capital provided by Homeowner</li>
                                    <li>No need for property managers</li>
                                    <li>Diversified residential property portfolio</li>
                                    </ul>
                                </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    );
};



export default Overview;
