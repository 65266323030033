import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import { postRequest } from "../../../helpers/apiHelper";
import CountdownTimerText from '../../../helpers/countdownTimerText';
import { convertUnixTimestampShort } from "../../../helpers/unixTimeConverter";
import ViewHeaderTitle from "../../../components/navigation/ViewHeaderTitle";

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
};

const Quotes = (props) => {
    const [quotes, setQuotes] = useState([]);
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(true);
    const { width } = useWindowSize(); // Get the window width

    useEffect(() => {
        const getLiveProperties = async () => {
            const responseData = await postRequest("/getAllQuotesForUser", {});
            console.log(responseData)
            setQuotes(responseData);
        };
        getLiveProperties();
    }, []);

    const handleViewClick = (quoteID) => {
        navigate(`/quote?quoteID=${quoteID}`);
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const renderTable = () => (
        <div className="responsive-table-wrapper">
            <table className="w-full rounded-lg overflow-hidden">
                <thead className="bg-gray-900 text-white">
                    <tr>
                        <th className="font-bold py-1 px-2 text-left border-b border-gray-300">Property address</th>
                        <th className="font-bold py-1 px-2 text-right border-b border-gray-300">Lease duration</th>
                        <th className="font-bold py-1 px-2 text-right border-b border-gray-300">Property price estimate</th>
                        <th className="font-bold py-1 px-2 text-right border-b border-gray-300">Quote status</th>
                        <th className="font-bold py-1 px-2 text-right border-b border-gray-300">Request date</th>
                        <th className="font-bold py-1 px-2 text-right border-b border-gray-300">Response ETA</th>
                        <th className="font-bold py-1 px-2 text-right border-b border-gray-300"></th>
                    </tr>
                </thead>
                <tbody>
                    {quotes.map((quote) => (
                        <tr className="bg-white cursor-pointer" key={quote.quoteID}>
                            <td className="border-b border-gray-200 py-1 px-2 text-left">{quote.propertyAddress.replace(', Australia', '')}</td>
                            <td className="border-b border-gray-200 py-1 px-2 text-right">{quote.nestAgreementDuration} years</td>
                            <td className="border-b border-gray-200 py-1 px-2 text-right">${new Intl.NumberFormat('en-AU', { style: 'decimal', maximumFractionDigits: 0 }).format(quote.propertyPurchasePriceEstimate)}</td>
                            <td className="border-b border-gray-200 py-1 px-2 text-right">{quote.quoteStatus}</td>
                            <td className="border-b border-gray-200 py-1 px-2 text-right">{convertUnixTimestampShort(quote.quoteRequestTime)}</td>
                            <td className="border-b border-gray-200 py-1 px-2 text-right">
                                {quote.quoteStatus == 'Requested' && (
                                    <>{convertUnixTimestampShort(Number(quote.quoteRequestTime) + 172800)}</>
                                )}
                            </td>
                            <td className="border-b border-gray-200 py-1 px-2 text-right">
                                {quote.quoteStatus == 'Fulfilled' && (
                                    <button className="primaryBG text-white px-2 rounded" onClick={() => handleViewClick(quote.quoteID)}>
                                        View
                                    </button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    const renderTiles = () => (
        <div className="grid grid-cols-1 gap-4">
            {quotes.map((quote) => (
                <div className="p-2 bg-white rounded-lg shadow-md flex justify-between items-center" key={quote.quoteID}>
                    <div>
                        <div className="text font-semibold mb-2">{quote.propertyAddress.replace(', Australia', '')}</div>
                        <div className={`text-xs font-semibold inline-block py-1 px-2 rounded text-white ${quote.quoteStatus === 'Requested' ? 'bg-red-600' : 'bg-green-500'} mb-2`}>
                            {quote.quoteStatus}
                        </div>
                        <div className="text-sm"><strong>Lease duration:</strong> {quote.nestAgreementDuration} years</div>
                        <div className="text-sm"><strong>Property price estimate:</strong> ${new Intl.NumberFormat('en-AU', { style: 'decimal', maximumFractionDigits: 0 }).format(quote.propertyPurchasePriceEstimate)}</div>
                        <div className="text-sm"><strong>Request date:</strong> {convertUnixTimestampShort(quote.quoteRequestTime)}</div>
                        <div className="text-sm">
                            <strong>Response ETA:</strong> 
                            {quote.quoteStatus == 'Requested' && (
                                <>{convertUnixTimestampShort(Number(quote.quoteRequestTime) + 172800)}</>
                            )}
                        </div>
                    </div>
                    {quote.quoteStatus == 'Fulfilled' && (
                        <button className="primaryBG text-white px-2 rounded" onClick={() => handleViewClick(quote.quoteID)}>
                            View Quote
                        </button>
                    )}
                </div>
            ))}
        </div>
    );

    return (
        <div className="p-2">
            <div className="mb-3">
                <h4>Nest agreement quotes</h4>
                <p className="text-sm text-gray-900">
                    We try to provide formal quotes within 48 hours to make sure you can bid on the property you love. When the quote is ready, you will see a 'View' button which will take you through to the quote.
                    You will also be notified when the quote is ready via email. Please make sure you check your junk folder!
                </p>
            </div>

            {isExpanded && (width > 768 ? renderTable() : renderTiles())}
        </div>
    );
};

export default Quotes;
