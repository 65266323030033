

export const convertUnixTimestampLong = (timestamp) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = new Date(timestamp * 1000);

    const hours = date.getHours();
    const minutes = `0${date.getMinutes()}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHour = hours % 12 || 12; // Convert 24h to 12h format and handle midnight

    return `${formattedHour}:${minutes}${ampm} ${day} ${month} ${year}`;
}


export const convertUnixTimestampShort = (timestamp) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = new Date(timestamp * 1000);

    const hours = date.getHours();
    const minutes = `0${date.getMinutes()}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHour = hours % 12 || 12; // Convert 24h to 12h format and handle midnight

    return `${day} ${month} ${year}`;
}