import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DocumentCheckIcon } from '@heroicons/react/24/outline';
import { getAuth } from "firebase/auth"; // Import necessary Firebase functions

const Support = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
            // User is signed in, use their information directly
            setName(user.displayName || ''); // Some users may not have a displayName set
            setEmail(user.email || '');
        } else {
            // User is signed out or session is invalid, handle accordingly
            navigate('/login'); // Redirect to login page or handle it as needed
        }
    }, [navigate]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!name || !email || !message) {
            setError("All fields are required.");
            return;
        }
        console.log("Form Submitted", { name, email, message });
        // navigate('/thank-you'); // Optionally redirect to a thank you page or handle next steps
    };

    return (
        <>        
            <div className="p-2">
                <div className="flex justify-center h-screen">
                    <form onSubmit={handleSubmit} style={{ minWidth: '400px' }} className="flex flex-col items-center">

                        <h2 className="text-2xl font-bold text-center mb-6">How can we help?</h2>
                        <div className="mb-4 w-full">
                            <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">
                                Your Name
                            </label>
                            <input
                                type="text"
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Enter your name"
                            />
                        </div>
                        <div className="mb-4 w-full">
                            <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
                                Email Address
                            </label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Enter your email"
                            />
                        </div>
                        <div className="mb-6 w-full">
                            <label htmlFor="message" className="block text-gray-700 text-sm font-bold mb-2">
                                Message
                            </label>
                            <textarea
                                id="message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Type your message here"
                                rows="4"
                            ></textarea>
                        </div>
                        {error && (
                            <p className="text-red-500 text-center italic">{error}</p>
                        )}
                        <button
                            type="submit"
                            className="primaryBG text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            <div className="flex justify-center items-center">
                                <DocumentCheckIcon className="h-5 w-5 mr-2" /> Submit
                            </div>
                        </button>
                    </form>
                </div>
            </div>
        
        </>
    );
};

export default Support;
