import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import {
    AcademicCapIcon,     
    DocumentCheckIcon,
    HomeIcon, 
    ChartBarIcon, 
    EyeIcon, 
    ReceiptRefundIcon,
    HeartIcon
} from '@heroicons/react/24/outline';


import LoadingModal from "./../../../components/LoadingModal";

import { postRequest, postRequestReturnAll } from "./../../../helpers/apiHelper";

const HomeScreen = (props) => {
    const navigate = useNavigate();
    
    const [loadingModalVisible, setLoadingModalVisible] = useState(true);

    const [showHomeContent, setShowHomeContent] = useState(false);

    
    
    useEffect(() => {
        
        const getNest = async () => {
            
            const nestResponse = await postRequest("/getNestForUser", {});
            
            if(nestResponse == null || nestResponse == "") {
                //Check whether a quote exists
                const quotesResponse = await postRequest("/getAllQuotesForUser", {});
                
                if(quotesResponse.length>0) {
                    navigate("/quotes")
                } else {
                    //No nest agreement or quote exists for the user
                    setLoadingModalVisible(false);
                    setShowHomeContent(true);
                }
            } else {
                navigate("/myNest")
            }
        };

        getNest();
        
    }, []);
    
    return (
        <>
            <LoadingModal loadingModalVisible={loadingModalVisible} />
            {showHomeContent && (
                <div className="p-2">
                    <div className="flex justify-center">
                        <div className="text-center">
                            <h2 className="font-bold">Welcome to nest</h2>
                            <p className="text-sm text-gray-900">
                                Let's help you get started!
                            </p>
                            
                            <img src={require("./../../../assets/images/icons/bird.png")} className="max-h-[20vh] mx-auto" />
                            
                            <div className="flex justify-center space-x-2 mt-3">
                                <button className="primaryBG text-white px-4 py-2 rounded flex items-center justify-center focus:outline-none w-[250px]"                            
                                    onClick={() => {
                                        navigate(`/overview`);
                                        
                                    }}>
                                    <AcademicCapIcon className="h-5 w-5 mr-1 text-white" aria-hidden="true" />
                                    What is nest
                                </button>
                                <button className="primaryBG text-white py-2 rounded flex items-center justify-center focus:outline-none w-[250px]"                            
                                    onClick={() => {
                                        navigate(`/estimate`);
                                        
                                    }}>
                                    <HeartIcon className="h-5 w-5 mr-1 text-white" aria-hidden="true" />
                                    I've found a property I want
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            
        </>
    );
};


export default HomeScreen;
