import React, { useState, useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./../views/navigation/SideBar";
import TopBar from "./../views/navigation/TopBar";

const AuthLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    if (window.innerWidth <= 768) { // Only close sidebar on mobile devices
      setIsSidebarOpen(false);
    }
  };

  const overlayRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        closeSidebar();
      }
    };

    if (isSidebarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarOpen]);

  return (
    <div className="flex min-h-screen relative">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      {isSidebarOpen && window.innerWidth <= 768 && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-6"
          onClick={closeSidebar}
          ref={overlayRef}
        ></div>
      )}
      <div className="flex flex-col flex-grow z-4">
        <TopBar toggleSidebar={toggleSidebar} />
        <div className="flex-grow overflow-auto bg-[#f0f2f5] p-2.5 text-[rgb(50,50,50)] py-16">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
