import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import Logo from "./../../../assets/images/nest logo light.png";
import { useNavigate } from 'react-router-dom';
import { UserPlusIcon, UserIcon, VideoCameraIcon, CalculatorIcon } from '@heroicons/react/24/outline';

const LandingPage = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsModalOpen(false);
    }
  };

  return (
    <div 
      className="min-h-screen text-white relative bg-center sm:bg-left"
      style={{
        backgroundImage: `url('/landingBG.jpg')`,
        backgroundSize: 'cover',
      }}
    >
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-80 z-0"></div>

      <div className="z-10 relative flex flex-col min-h-screen">
        <header className="w-full flex justify-between items-center px-3 py-3">
          <div className="logo">
            <img src={Logo} alt="Logo" className="h-12 sm:h-20 object-contain"/>
          </div>
          <div className="flex justify-end w-full sm:w-auto space-x-2">
            <button 
              className="primaryBG text-white px-3 py-2 rounded flex items-center justify-center w-32" // consistent width
              onClick={() => navigate('/signup')}
            >
              <UserPlusIcon className="h-5 w-5 mr-2" /> Sign up
            </button>
            <button 
              className="primaryBG text-white px-3 py-2 rounded flex items-center justify-center w-32" // consistent width
              onClick={() => navigate('/login')}
            >
              <UserIcon className="h-5 w-5 mr-2" /> Login
            </button>
          </div>
        </header>
        <main className="flex-grow flex items-center justify-center">
          <div className="text-center px-4 flex flex-col items-center">
            <h1 className="text-xl sm:text-3xl font-bold">A new way to own a house</h1>
            <button 
              className="mt-2 bg-gray-900 text-white px-5 py-2 rounded flex items-center justify-center w-full sm:w-56 focus:outline-none" // adjusted for better width control
              onClick={() => setIsModalOpen(true)}
            >
              <VideoCameraIcon className="h-5 w-5 mr-2" /> Learn more
            </button>
            <button 
              className="mt-2 primaryBG text-white px-5 py-2 rounded flex items-center justify-center w-full sm:w-56 focus:outline-none" // consistent width across views
              onClick={() => navigate('/quickEstimate')}
            >
              <CalculatorIcon className="h-5 w-5 mr-2" /> Get estimate
            </button>
          </div>
        </main>
        
        {isModalOpen && (
          <motion.div
            className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-20"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={handleClickOutside}
          >
            <div 
              ref={modalRef}
              onClick={(e) => e.stopPropagation()}
              className="bg-white rounded-lg shadow-lg overflow-hidden"
            >
              <iframe 
                width="1000" height="500"
                src="https://www.youtube-nocookie.com/embed/z2judsbo-5E?autoplay=1&mute=1"
                title="YouTube video player"
                frameBorder="0"
                allowFullScreen
                className="w-full h-full"
              ></iframe>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default LandingPage;
