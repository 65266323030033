import React, { useContext } from "react";
import { getAuth, signOut } from "firebase/auth";

export const logout = () => {
    const auth = getAuth();

    signOut(auth).then(() => {
        // Sign-out successful.
        // Remove items in local storage
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("uid");
        localStorage.removeItem("email");

        // Redirect to signin
        window.location.href = "/";
    }).catch((error) => {
        // An error happened.
        console.error("Logout Error:", error);
    });
}
