import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for prop validation
import { CheckCircleIcon } from '@heroicons/react/24/solid'; // Ensure you have heroicons installed

const QuoteRequestConfirmationModal = ({ modalVisible, modalActionFunction }) => {
  if (!modalVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 px-4">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
        <div className="flex flex-col items-center">
          <CheckCircleIcon className="h-12 w-12 text-customOrange" /> {/* Updated class name */}
          <p className="text-center text-gray-700 mt-2">
            Your quote has been successfully submitted. You will hear back from the nest concierge via email within the next 48 hours. In the meantime, please don't hesitate to reach out if you have any further questions.
          </p>
          <button
            className=" mt-2 bg-customOrange text-white rounded py-1 px-4 hover:bg-customOrange-600  focus:outline-none " // Custom color used here
            onClick={modalActionFunction}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

QuoteRequestConfirmationModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  modalActionFunction: PropTypes.func.isRequired, // Add PropTypes validation for the new prop
};

export default QuoteRequestConfirmationModal;
