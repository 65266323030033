import React, { useState } from "react";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import Logo from "./../../../assets/images/nest logo.png";
import { useNavigate, Link } from "react-router-dom";
import { UserIcon } from '@heroicons/react/24/outline';
import LoadingModal from "../../../components/LoadingModal";

// Import helpers
import { postRequest } from "../../../helpers/apiHelper";

const SignUp = () => {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");

    // Validation functions
    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    const validatePhone = (phoneNumber) => {
        const re = /^\+61\d{9}$/;  // Australian e.164 format
        return re.test(phoneNumber);
    };

    const validatePassword = (password) => {
        const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        return re.test(password);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let validationErrors = [];
        if (!fullName) validationErrors.push("Full name is required.");
        if (!email || !validateEmail(email)) validationErrors.push("Please enter a valid email address.");
        if (!phoneNumber || !validatePhone(phoneNumber)) validationErrors.push("Please enter a valid Australian phone number in +61 format.");
        if (!password || !validatePassword(password)) validationErrors.push("Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one digit.");
        if (password !== confirmPassword) validationErrors.push("Passwords do not match.");
        if (validationErrors.length > 0) {
            setError(validationErrors.join(" "));
            return;
        }

        setIsLoading(true);
        const auth = getAuth();
        createUserWithEmailAndPassword(auth, email, password)
            .then(userSignUpData => {
                console.log(userSignUpData);
                return signInWithEmailAndPassword(auth, email, password);
            })
            .then(userData => {
                console.log(userData);
                localStorage.setItem("refreshToken", userData.user.stsTokenManager.refreshToken);
                localStorage.setItem("uid", userData.user.uid);
                localStorage.setItem("displayName", userData.user.displayName || ''); // Handle null displayName
                localStorage.setItem("email", userData.user.email);
                return postRequest("/updateUser", {
                    userID: userData.user.uid,
                    email: email,
                    phoneNumber: phoneNumber,
                    displayName: fullName
                });
            })
            .then(userUpdate => {
                if (userUpdate === 'Phone number already in use') {
                    setError('Phone number already in use');
                } else {
                    console.log(userUpdate);
                    navigate('/');
                }
            })
            .catch(error => {
                console.error("Error during user sign-up or update:", error);
                if(error.message == 'Firebase: Error (auth/email-already-in-use).') {
                    setError("Email already in use");
                } else {
                    setError(error.message || "Error in Sign Up");
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <div className="min-h-screen text-white relative"
                style={{
                    backgroundImage: 'url("/signinBG.jpg")',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <LoadingModal loadingModalVisible={isLoading} />

                <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-60 z-0">
                    <div className="min-h-screen flex items-stretch justify-end z-10">
                        <div className="flex flex-col justify-center items-center bg-gray-50 px-8 py-3"
                            style={{ borderRadius: '0' }}
                        >
                        <div className="text-center mb-4">
                            <img className="mx-auto logo" src={Logo} alt="" width="200" />
                        </div>
                            <form onSubmit={handleSubmit} autoComplete="off" className="w-full">
                            <div className="mb-2">
                              <label htmlFor="fullName" className="block text-gray-700 text-sm font-bold mb-2">
                                Full Name
                              </label>
                              <input
                                className="appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline rounded"
                                id="fullName"
                                type="text"
                                placeholder="Enter Full Name"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                              />
                            </div>
                            <div className="mb-2">
                              <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
                                Email Address
                              </label>
                              <input
                                className="appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline rounded"
                                id="email"
                                type="email"
                                placeholder="Enter Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                            <div className="mb-2">
                              <label htmlFor="phone" className="block text-gray-700 text-sm font-bold mb-2">
                                Phone Number
                              </label>
                              <input
                                className="appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline rounded"
                                id="phone"
                                type="tel"
                                pattern="^\+61\d{9}$"
                                placeholder="Enter Phone (+61xxxxxxxxx)"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                              />
                            </div>
                            <div className="mb-2">
                              <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
                                Password
                              </label>
                              <input
                                className="appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline rounded"
                                id="password"
                                type="password"
                                placeholder="Enter Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>
                            <div className="mb-4">
                              <label htmlFor="confirmPassword" className="block text-gray-700 text-sm font-bold mb-2">
                                Retype Password
                              </label>
                              <input
                                className="appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline rounded"
                                id="confirmPassword"
                                type="password"
                                placeholder="Retype Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                              />
                            </div>
                            <div className="mb-2">
                              <button
                                className="primaryBG text-white font-bold py-2 px-4 w-full focus:outline-none focus:shadow-outline rounded"
                                type="submit"
                              >
                                <div className="flex items-center justify-center">
                                  <UserIcon className="h-5 w-5 mr-2" /> Sign Up
                                </div>
                              </button>
                            </div>

                            {error && (
                              <div className="max-w-[300px]">
                                <p className="text-red-700 italic text-center">{error}</p>
                              </div>
                            )}

                            <div className="text-center">
                              <Link
                                to="/login"
                                className="inline-block align-baseline font-bold text-sm text-gray-700 hover:no-underline hover:text-gray-900"
                              >
                                Already have an account? Log in
                              </Link>
                            </div>
                          </form>
                              </div>
                          </div>
                      </div>
                  </div>
                  
            <style jsx>{`
                @media (min-width: 769px) {
                    .flex.flex-col {
                        width: 400px; // Fixed width for non-mobile devices
                    }
                }
                @media (max-width: 768px) {
                    .flex.flex-col {
                        width: 100%; // Ensure full width on mobile devices
                    }
                }
                .logo {
                    width: 200px;
                }
            `}</style>


        </>
    );
};

export default SignUp;
